<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
import pusher from '@/pusher'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderHome',
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData')),
  }),
  computed: {
    ...mapGetters('order', ['_orders']),
  },
  created() {
    this.$store.dispatch('company/getUserCompanies', this.userData.user_uuid)
    const channel = pusher.subscribe('tamyeri-ticaret')
    channel.bind('invoice-created-event', data => {
      const orderIndex = this._orders.findIndex(order => order.id === data.invoice.order_id)
      this._orders[orderIndex].invoice = data.invoice
    })
  },
}
</script>

<style scoped>

</style>
